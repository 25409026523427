import * as React from "react"
import { graphql } from "gatsby"
import DefaultLayout from "../components/DefaultLayout"
import styled from "styled-components"

const ContentStyle = styled.div`
    h1 {
        color: ${({ theme }) => theme.colours.secondary};
    }

    h2 {
        color: ${({ theme }) => theme.colours.tertiary};
    }

    strong {
        color: ${({ theme }) => theme.colours.highlight};
    }
`

const Template = (data: {
    data: { markdownRemark: { html: string } }
}): JSX.Element => {
    const { html } = data.data.markdownRemark
    return (
        <DefaultLayout>
            <ContentStyle dangerouslySetInnerHTML={{ __html: html }} />
        </DefaultLayout>
    )
}

export default Template

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
            }
        }
    }
`
